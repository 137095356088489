<template lang="pug">
header.FcwCommunicationPreferencesNavbar
  .FcwCommunicationPreferencesNavbar__menuStart
    FcwRouterLink(
      :location="homeRoute"
      hide-arrow
      color="primary"
      hover-color="primary--dark-1"
    )
      FcwClientLogo
    FcwChip.FcwCommunicationPreferencesNavbar__previewChip(
      v-if="isPreview"
      :label="t('common__preview_mode')"
    )

  .FcwCommunicationPreferencesNavbar__menuEnd
    FcwButtonLink(
      v-if="clientState.modules.profile || clientState.modules.accountSettings"
      :location="clientState.modules.profile ? { name: 'profile' } : { name: 'account-settings' }"
      color="neutral--light-5"
      background="primary"
    )
      span {{ t('common__my_profile') }}
      FIcon(name="user")
</template>

<style lang="stylus">
.FcwCommunicationPreferencesNavbar
  display flex
  justify-content space-between
  align-items center
  height rem(80)
  elevation-light(3)
  border-radius 0 0 rem(24) rem(24)
  padding-left rem(24)
  padding-right rem(16)
  background var(--color--neutral--light-5)

  > * + *
    margin-left rem(24)

.FcwCommunicationPreferencesNavbar__menuStart
  display flex
  align-items center
  gap rem(16)

.FcwCommunicationPreferencesNavbar__menuEnd
  display flex
  align-items center
  color var(--color--neutral--dark-2)
  font-weight 400
  overflow auto
  padding rem(8)

  > *
    white-space nowrap
    flex-grow 0

  > * + *
    margin-left rem(32)
    white-space nowrap
</style>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

export interface FcwCommunicationPreferencesNavbarProps {
  /**
   * Route to Home. Route will be applied on menuStart block
   */
  homeRoute?: RouteLocationRaw;
}

withDefaults(defineProps<FcwCommunicationPreferencesNavbarProps>(), {
  homeRoute: '',
});

const { t } = useClientI18n();
const { isPreview } = useGlobalStore();

const { clientState } = useClientStore();
</script>
