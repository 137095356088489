<template lang="pug">
.communication-preferences
  FcwCommunicationPreferencesNavbar.communication-preferences__header
  .communication-preferences__content
    .communication-preferences__contentWrapper
      NuxtPage(
        :transition="{ name: 'slide-left', mode: 'out-in', duration: 200 }"
      )
  FcwFooter(v-bind="accountSettingsFooterProps")
</template>

<style lang="stylus">
.communication-preferences
  min-height 100%
  display flex
  flex-direction column
  justify-content space-between
  background var(--color--neutral--light-4)

.communication-preferences__header
  position fixed
  z-index 5
  width 100%

.communication-preferences__content
  display flex
  flex-direction column
  position relative
  height 100%
  width 100%
  overflow hidden
  margin 0 auto
  color var(--color--neutral--dark-4)
  padding rem(128) rem(64) rem(56)
  min-height 80vh

  +media-down('sm')
    padding 0
    padding-top rem(96)
</style>

<script setup lang="ts">
import FcwCommunicationPreferencesNavbar from '@/components/organisms/FcwCommunicationPreferencesNavbar.vue';

const accountSettingsFooterProps = await useClientFooter('minimal');
</script>
